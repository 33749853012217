import '../stylesheets/application';
import './rails-ujs.js'
import '@fortawesome/fontawesome-free/js/all'
import Vue from 'vue/dist/vue.esm'

document.addEventListener('DOMContentLoaded', () => {
  new Vue({
    el: '#navbar-burger',
    data: {
      isOpen: false
    }
  })
})
